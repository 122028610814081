// import React from 'react';
// import ReactDOM from 'react-dom/client';

// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import './index.css';
import App from './App';
import config from './config/config';
import { registerSW } from 'virtual:pwa-register';

Sentry.init({
  dsn: config.SENTRY_DNS,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/payments\.sendity\.com/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

const container = document.getElementById('root');

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <GoogleReCaptchaProvider reCaptchaKey="6LedDiMjAAAAAI3_2_N7wZdpS4JAhjJQeJ-nPLPY">
    <App />
  </GoogleReCaptchaProvider>
);

const updateSW = registerSW({
  inmiddiate: true,
  onNeedRefresh() {
    console.log('A new version is available. Updating automatically...');
    updateSW(); // Immediately apply the update
  },

  onOfflineReady() {
    console.log('The app is ready to work offline.');
  }
});

const DEPLOYMENT_DATE_KEY = 'lastDeploymentDate'; // Key to store the date locally

async function checkForUpdate() {
  try {
    // Fetch the version.json file, avoiding caching
    const response = await fetch('/version.json', { cache: 'no-store' });
    const { deploymentDate: serverDate } = await response.json();

    // Get the saved date from localStorage
    const savedDate = localStorage.getItem(DEPLOYMENT_DATE_KEY);

    // Parse dates or fallback to Epoch if savedDate is missing/invalid
    const parsedSavedDate = savedDate ? new Date(savedDate) : new Date(0);
    const parsedServerDate = new Date(serverDate);

    // Compare dates and handle new deployments
    if (parsedServerDate > parsedSavedDate) {
      console.log(
        `New deployment detected (Server: ${parsedServerDate}, Local: ${parsedSavedDate}).`
      );

      // Update localStorage and refresh for debugging
      localStorage.setItem(DEPLOYMENT_DATE_KEY, parsedServerDate.toISOString());
      console.log('Refreshing page to load new deployment.');
      window.location.reload();
    } else {
      console.log('No new deployment detected.');
    }
  } catch (err) {
    console.error('Failed to check for updates:', err);
  }
}

// Run the check periodically and on load
const CHECK_INTERVAL = 60 * 1000; // Check every 1 min
setInterval(checkForUpdate, CHECK_INTERVAL);
checkForUpdate(); // Also check immediately on load
