import { lazy } from 'react';

// transacciones:
const Transactions = lazy(() => import('../../Components/Pages/transactions/Transactions'));

// Beneficiarios:
const Beneficiary = lazy(() => import('../../Components/Pages/beneficiary/Beneficiary'));

// Incidencias:
const Incidents = lazy(() => import('../../Components/Pages/incidents/Incidents'));

// Paginas usuario:
const Perfil = lazy(() => import('../../Components/Pages/perfil/Perfil'));
const ChangeProfile = lazy(() => import('../../Components/Pages/changeProfile/ChangeProfile'));
const CheckEmail = lazy(() => import('../../Components/Pages/Register/RegisterValidateEmail'));
const ShipmentSuccess = lazy(() =>
  import('../../Components/Pages/shipmentSuccess/ShipmentSuccess')
);
const RegisterManager = lazy(() => import('../../Components/Pages/Register/RegisterManager'));
const RegisterTransferProcess = lazy(() =>
  import('../../Components/Pages/Register/RegisterTransferProcess')
);
const RegisterRedirection = lazy(() =>
  import('../../Components/Pages/Register/RegisterRedirection')
);
const SessionExpired = lazy(() => import('../../Components/Pages/login/sessionExpired'));
const TopUpPhone = lazy(() => import('../../Components/Pages/topUpPhone/TopUpPhone'));
const TopUpPhoneTariff = lazy(() => import('../../Components/Pages/topUpPhone/TopUpPhoneTariff'));
const TopUpPhoneConfirm = lazy(() => import('../../Components/Pages/topUpPhone/TopUpPhoneConfirm'));
const TopUpPhoneSuccess = lazy(() => import('../../Components/Pages/topUpPhone/TopUpPhoneSuccess'));
const TopUpPhoneFailed = lazy(() => import('../../Components/Pages/topUpPhone/TopUpPhoneFailed'));

// Meal Packs
const MealPackStepOne = lazy(() => import('../../Components/Pages/mealPack/MealPackStepOne'));
const MealPackStepTwo = lazy(() => import('../../Components/Pages/mealPack/MealPackStepTwo'));
const MealPackFinishStep = lazy(() => import('../../Components/Pages/mealPack/MealPackFinishStep'));
const MealPackRegions = lazy(() => import('../../Components/Pages/mealPack/MealPackSelectRegion'));

// generate payment url, Dtone
const GenerateUrl = lazy(() => import('../../Components/Pages/topUpPhone/GenerateUrl'));

// Request pin again
const RequestPinAgain = lazy(() => import('../../Components/Pages/login/RequestPinAgain'));

// Error404
const Error404 = lazy(() => import('../../Components/Pages/error404/Error404'));

// Mantenimiento
const Maintenance = lazy(() => import('../../Components/Pages/maintenance/Maintenance'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: `/profile`, exact: true, name: `Profile`, element: Perfil },
  {
    path: `/changeprofile`,
    exact: true,
    name: `Change user`,
    element: ChangeProfile
  },
  {
    path: `/signup/:route/:urlstep`,
    name: `Register`,
    element: RegisterManager
  },
  {
    path: `/remittances/start-process`,
    name: `RegisterRedirection`,
    element: RegisterRedirection
  },
  {
    path: `/session-expired`,
    name: `SessionExpired`,
    element: SessionExpired
  },
  /*   {
    path: `/login/pin`,
    name: `Login PIN`,
    element: LoginRevolupay
  }, */
  {
    path: `/transactions`,
    exact: true,
    name: `Transactions`,
    element: Transactions
  },
  {
    path: `/beneficiary`,
    exact: true,
    name: `Beneficiary`,
    element: Beneficiary
  },
  {
    path: `/incidents`,
    exact: true,
    name: `Incidents`,
    element: Incidents
  },
  {
    path: `/checkemail`,
    exact: true,
    name: `CheckEmail`,
    element: CheckEmail
  },

  {
    path: `/topups/select-mobile`,
    exact: true,
    name: `TopUpPhone`,
    element: TopUpPhone
  },
  {
    path: `/topups/select-amount`,
    exact: true,
    name: `TopUpPhoneTariff`,
    element: TopUpPhoneTariff
  },
  {
    path: `/topups/review-amount`,
    exact: true,
    name: `TopUpPhoneConfirm`,
    element: TopUpPhoneConfirm
  },
  {
    path: `/topups/confirmed`,
    exact: true,
    name: `TopUpPhoneSuccess`,
    element: TopUpPhoneSuccess
  },
  {
    path: `/topups/failed`,
    exact: true,
    name: `TopUpPhoneFailed`,
    element: TopUpPhoneFailed
  },
  {
    path: `/topups/topupko`,
    exact: true,
    name: `TopUpPhoneFailed`,
    element: TopUpPhoneFailed
  },
  {
    path: `/transfer/confirmed/:transactionId`,
    exact: true,
    name: `ShipmentSuccess`,
    element: ShipmentSuccess
  },
  /*  {
    path: `/mealpack/select-pack`,
    exact: true,
    name: `MealPackStepOne`,
    element: MealPackStepOne
  },
  {
    path: `/mealpack/confirm-pack`,
    exact: true,
    name: `MealPackStepTwo`,
    element: MealPackStepTwo
  },
  {
    path: `/mealpack/confirm-paid/:id`,
    exact: true,
    name: `MealPackFinishStep`,
    element: MealPackFinishStep
  },
  {
    path: `/mealpack/select-region`,
    exact: true,
    name: `Select region meal pack`,
    element: MealPackRegions
  },  {
    path: `/register/completed/checkemail`,
    exact: true,
    name: `Register completed`,
    element: CreateStepCompletedCheckEmail,
  },
  {
    path: `/checkemail`,
    exact: true,
    name: `Incidents`,
    element: CheckEmail,
  },
  {
    path: `/shipment/success`,
    exact: true,
    name: `Recover pass send new pass`,
    element: ShipmentSuccess,
  },
  {
    path: `/register/:route/:urlstep`,
    name: `Register`,
    element: RegisterManager,
  }, */
  {
    path: `/404`,
    exact: true,
    name: `Error, page not found`,
    element: Error404
  },
  {
    path: `/transferprocess`,
    name: `TransferProcess`,
    element: RegisterTransferProcess
  },
  {
    path: `/generateurl`,
    name: `Generate Url`,
    element: GenerateUrl
  },
  {
    path: `/getpinagain`,
    name: `Get pin again`,
    element: RequestPinAgain
  },
  {
    path: `/maintenance`,
    name: `Maintenance`,
    element: Maintenance
  }
];

export default routes;
